import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import logostrukturni from "../images/logo-strukturni.png"
import logoeu from "../images/logo-eu.png"
import logohr from "../images/logo-hr.png"
import logokohezija from "../images/logo-kohezija.png"
import logozluk from "../images/logo-zluk-horizontalni.png"
import logopgz from "../images/logo-pgz.png"

const Footer = () => (
  <>
    <footer>
      <div className="footer-inner">
        <section className="footer-branding">
          <section className="footer-logotipi">
            <img src={logohr} className="" alt="Hrvatska" />
            <img
              src={logostrukturni}
              className=""
              alt="EU strukturni fondovi"
            />
            <img src={logoeu} className="" alt="Logo EU" />
            <img src={logokohezija} className="" alt="EU kohezijski fondovi" />
            <img
              src={logozluk}
              className=""
              alt="Županijska lučka uprava Krk"
            />
            <img src={logopgz} className="" alt="Primorsko-goranska županija" />
          </section>
          <p>
            <strong>
              PROJEKT JE SUFINANCIRALA EUROPSKA UNIJA IZ KOHEZIJSKOG FONDA
            </strong>
          </p>
          <p>
            Sadržaj web stranice isključiva je odgovornost Županijske lučke
            uprave Krk.
            <br />
            Za više informacija o EU fondovima posjetite internetsku stranicu
            Ministarstva regionalnoga razvoja i fondova Europske unije{" "}
            <a href="https://www.razvoj.gov.hr">www.razvoj.gov.hr</a> <br />i
            stranicu Europskih strukturnih i investicijskih fondova{" "}
            <a href="https://www.strukturnifondovi.hr">
              www.strukturnifondovi.hr
            </a>
            <br />
            Dogradnju ove web stranice sufinancirala je Europska unija iz
            Kohezijskog fonda
            <br />© {new Date().getFullYear()}
          </p>
        </section>
        <section>
          <p>
            Trg bana Josipa Jelačića 5, 51500 Krk, Hrvatska | Tel.: +385 51 220
            165 | Faks: +385 51 880 072
            <br />
            e-mail: info@zluk.hr | web:{" "}
            <a href="https://www.zluk.hr">www.zluk.hr</a>
          </p>
          <p>
            Osoba za više informacija o projektu:
            <br />
            Andrea Dujmović | andrea@zluk.hr | Tel.: +385 51 220 165{" "}
          </p>
          <p>
            <Link to={"/izjava-o-pristupacnosti"}>Izjava o pristupačnosti</Link>
          </p>
        </section>
      </div>
    </footer>
    {/* <section className="footer-contact">
      <p>
        Trg bana Josipa Jelačića 5, 51500 Krk, Hrvatska
        <br />
        Tel.: +385 51 220 165 | Faks: +385 51 880 072
        <br />
        e-mail: info@zluk.hr | web:{" "}
        <a href="https://www.zluk.hr">www.zluk.hr</a>
      </p>
      <p>
        Osoba za više informacija o projektu:
        <br />
        Ana Jelovac <br />
        ana.jelovac@zluk.hr | Tel.: +385 51 220 165{" "}
      </p>
      <p>
        <Link to={"/izjava-o-pristupacnosti"}>Izjava o pristupačnosti</Link>
      </p>
    </section> */}
  </>
)

Footer.propTypes = {}

Footer.defaultProps = {}

export default Footer
