import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logobaska from "../images/logo-baska.png"
import logoeu from "../images/logotipi-eu.png"

const NavLink = props => (
  <Link to={props.to}>{props.children}</Link>
)

const Header = ({ siteTitle }) => (
<>

  <header>
    <div className="header-logo">
      <img src={logobaska} className="logo-baska" alt="Luka Baška" />
    </div>
     <div className="header-logo-eu">
      <img src={logoeu} className="logo-eu" alt="EU fondovi" />
      </div>
  </header>
  <div className="navigation">
    <nav className="main-nav">
      <NavLink to="/">Naslovnica</NavLink>
      <NavLink to="/o-projektu/">O projektu</NavLink>
      <NavLink to="/novosti/">Novosti</NavLink>
      <NavLink to="/iz-medija/">Iz medija</NavLink>
      <NavLink to="/galerija/">Galerija</NavLink>
      <a href="http://www.zluk.hr" className="link-zluk">ŽLUK Homepage</a>
    </nav>
    </div>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
